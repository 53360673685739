import { ProductList_Item } from "../Item/Item.component";
import { useEffect, useState } from "react";
import { CategoryProductList } from "@shared/types/CategoryTypes.interface";
import { FavoriteItem } from "@shared/helpers/favoritesHelper";
import {
    ProductPriceResponse,
    fetchProductPrice,
    fetchProductPrices,
} from "@shared/helpers/apis/fetchProductPrices";
import { useTranslations } from "@shared/context/useTranslations";
import S from "./List.module.scss";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";
import { IconNames } from "@shared/enums/icons.enums";

interface ProductList_ListProps<T> {
    products: T extends CategoryProductList
        ? CategoryProductList
        : { items: FavoriteItem[]; totalCount: number };
    path?: string;
    showTotalCount?: boolean;
    onGetQuantities?: (quantities: Record<string, number>) => void;
}

export const ProductList_List = <
    T extends CategoryProductList | { items: FavoriteItem[]; totalCount: number },
>({
    products,
    path,
    showTotalCount = true,
    onGetQuantities,
}: ProductList_ListProps<T>) => {
    const [productPrices, setProductPrices] = useState<Record<string, ProductPriceResponse>>({});
    const [quantities, setQuantities] = useState<Record<string, number>>({});
    const { getTranslation } = useTranslations();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentCulture = globalThis?.currentCulture || "en-gb";

    // Path for favorites

    useEffect(() => {
        const fetchPrices = async () => {
            const productIds = products.items.map((product) => product.id.toString());
            const prices = await fetchProductPrices(productIds, currentCulture);

            setProductPrices(prices);
            const initialQuantities = productIds.reduce(
                (acc, id) => {
                    acc[id] = 1; // Default quantity to 1
                    return acc;
                },
                {} as Record<string, number>
            );
            setQuantities(initialQuantities);
        };

        fetchPrices();
    }, [products.items, currentCulture]);

    useEffect(() => {
        if (onGetQuantities) {
            onGetQuantities(quantities);
        }
    }, [quantities, onGetQuantities]);

    const handleQuantityChange = async (productId: string, newQuantity: number) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: newQuantity,
        }));

        const newPrice = await fetchProductPrice(productId, newQuantity, currentCulture);
        setProductPrices((prevPrices) => ({
            ...prevPrices,
            [productId]: newPrice,
        }));
    };

    return (
        <div className={S.productListWrapper}>
            {showTotalCount && (
                <span className={S.counter}>
                    {`${products.totalCount} ${getTranslation("general.products")}`}
                </span>
            )}

            {products.totalCount! > 0 ? (
                products.items.map((product) => (
                    <ProductList_Item
                        key={product.id}
                        product={product}
                        path={path}
                        prices={productPrices[product.id]}
                        quantity={quantities[product.id]}
                        onQuantityChange={(newQuantity) =>
                            handleQuantityChange(product.id.toString(), newQuantity)
                        }
                    />
                ))
            ) : (
                <EmptyState
                    iconName={IconNames.Search}
                    heading={getTranslation("search.no-results")}
                />
            )}
        </div>
    );
};
